<template>
  <div class="card-container">
    <div class="flex-align" style="padding-bottom: 16px">
      <div class="position-relative" style="overflow: hidden;border-radius: 12px">
        <img class="product-img" :src="img" :alt="card.product.name">
        <div class="dim" v-if="card.status===3">이용중지</div>
      </div>
      <div style="padding:0 24px;flex:1">
        <div class="subtitle5 main">{{ card.product.name }}</div>
        <div class="body5 sub3">{{ card.product.simple_desc }}</div>
        <!--금액 -->
        <div class="flex-align" v-if="!card.product.prime && card.product.category_name!=='추가서비스'">
          <div class="margin-right-4 primary body4-bold" v-if="card.product.discount_type===0">{{ discountText(card.product) }}</div>
          <div class="body4-bold">
            <span class="sub">{{ card.product.price | currencyNum }}</span>
            <span class="body4 sub2">원</span>
            <span class="body4 sub3" v-if="card.date_unit > 0">
                <span v-if="card.date_unit>1">/{{ card.date_unit }}개월</span><span v-else>/월</span></span>
          </div>
        </div>
      </div>
      <!-- 상태 / 버튼 -->
      <div>
        <div class="flex-end">
          <div style="margin-right:12px">
            <template v-if="card.status===2">
              <template v-if="showNextDate">
                <span class="body5 sub3" style="margin-right: 4px">결제 예정일</span>
                <span class="body5-medium sub">{{ datesFormat(card.next_date, 'date_3') }}</span>
              </template>
            </template>
            <template v-else-if="card.status===3">
              <span class="body5 sub3" style="margin-right: 4px">이용 종료일</span>
              <span class="body5-medium sub">{{ datesFormat(card.end_date, 'date_3') }}</span>
            </template>
          </div>
          <template v-if="card.product.prime || card.product.category_name==='추가서비스'">
            <div v-if="card.status===1"
                 class="body4 sub3"
                 style="width:120px;line-height:32px;text-align:center;">신청 확인중</div>
            <div v-else-if="card.status===6"
                 class="card-status-3" @click="clickInquiryDetail">상세내역</div>

            <div v-else-if="card.status===2" class="flex-align unselect" style="gap:12px">
              <div v-if="!card.has_review" class="button-review unselect" @click="clickReviewReg">후기작성</div>
              <div class="card-status-2 unselect" @click="clickFeature">기능설정</div>
            </div>
            <div v-else
              :class="`card-status-${card.status} ${card.status === 3 ? 'unselect' : ''}`" @click="clickStatus">
              {{ statusText(card.status) }}
            </div>


          </template>
          <template v-else>
            <div v-if="card.status===2" class="flex-align unselect" style="gap:12px">
              <div v-if="!card.has_review" class="button-review unselect" @click="clickReviewReg">후기작성</div>
              <div class="card-status-2 unselect" @click="clickFeature">기능설정</div>
            </div>
            <template v-else>
              <div :class="`card-status-${card.status} ${card.status === 3 ? 'unselect' : ''}`" @click="clickStatus">
                {{ statusText(card.status) }}
              </div>
            </template>
          </template>
        </div>
        <div class="flex-end unselect" style="padding:6px 20px;margin-top:8px" @click="expand = !expand">
          <div class="body4 sub2">상세보기</div>
          <img :src="`/static/icon/u_angle-${expand ? 'up' : 'down'}.svg`" style="width:20px;margin-left:4px">
        </div>
      </div>
    </div>
    <!-- 구매 정보 -->
    <div v-if="expand" class="box-info position-relative">
      <template v-if="card.status === 1">
        <div class="body4-bold main">신청 정보</div>
        <div class="flex sub2" style="margin-top:8px">
          <div class="info-title body4-medium">신청일<br>결제 예정 금액</div>
          <div class="info-content body4">
            {{ datesFormat(card.created_time, 'date_3') }}<br>
            {{ card.price|currency }}
            <span v-if="card.date_unit>0">
              <span v-if="card.date_unit>1">
                    /{{ card.date_unit }}개월
              </span>
              <span v-else>/월</span>
            </span>
          </div>
          <button class="button is-gray button-stop" @click="clickInquiry('')">1:1문의</button>
        </div>
      </template>
      <template v-else>
        <div class="body4-bold main">구매 정보</div>
        <div class="flex sub2" style="margin-top:8px">
          <div class="info-title  body4-medium">
            구매일<br>
            <template v-if="card.free_date">무료 이용기간<br></template>
            <template v-if="card.status===2">
              <template v-if="showNextDate">결제 예정일<br></template>결제 예정 금액
            </template>
            <template v-else>서비스 이용기간</template>
          </div>
          <div class="info-content body4">
            {{ datesFormat(card.created_time, 'date_3') }}<br>
            <template v-if="card.free_date">
              {{ datesFormat(card.created_time, 'date_3') + ' ~ ' + datesFormat(card.free_date, 'date_3') }}<br>
            </template>
            <template v-if="card.status===2">
              <template v-if="showNextDate">{{ datesFormat(card.next_date, 'date_3') }}<br></template>
              {{ card.price|currency }}
              <span v-if="card.date_unit>0">
                <span v-if="card.date_unit>1">
                    /{{ card.date_unit }}개월
                  </span>
                  <span v-else>/월</span>
              </span>
            </template>
            <template v-else>
              {{ datesFormat(card.created_time, 'date_3') + ' ~ ' + datesFormat(card.end_date, 'date_3') }}
            </template>
          </div>
          <button v-if="card.status===2 && card.date_unit > 0" class="button is-gray button-stop" @click="clickStop">이용해지</button>
          <div class="text-right" v-else-if="card.status===2 && card.date_unit === 0">
            <div>이용해지 불가</div>
            <div class="body8-medium margin-top-4">*건 결제 서비스는 중도 해지가 되지 않습니다.</div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="vModal" class="stop-modal-container">
      <sweet-modal overlay-theme="dark" ref="stopModal" @close="onCloseModal" :hideCloseButton="true">
        <div>
          <div class="modal-header">
            <div>이용해지</div>
            <img src="/static/icon/fi_close_outline.svg" class="svg-sub2 unselect" @click="$refs.stopModal.close()">
          </div>
          <div style="padding:120px 54px">
            <svg width="54" height="54" viewBox="0 0 24 24" fill="none">
              <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15 9L9 15" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 9L15 15" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="subtitle4 main" style="margin-top:20px">
              <div>추가한 {{ terminateModalData[this.card.app_type].title }}를 <br> 이용 중지하시겠습니까?</div>
              <div class="body4 margin-top-8 margin-bottom-16"
                   style="color:#363636">{{ datesFormat(this.card.next_date, 'date_3') }}에 결제 예정</div>
              <div class="flex-center">
                <div style="width:360px;text-align:left">
                  <ul style="list-style:disc">
                    <template v-for="(content, c_idx) in terminateModalData[this.card.app_type].contents">
                      <li :key="`content-${c_idx}`" class="body4 sub3" style="margin-top:8px">
                        {{ content }}
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>

            <div class="flex-center" style="margin-top:60px">
              <div class="buttons">
                <button class="button is-primary" @click="clickInquiry('terminateModal')">1:1 문의</button>
                <button class="button is-gray" @click="openTerminateModal">이용 중지 신청</button>
              </div>
            </div>
          </div>
        </div>
      </sweet-modal>
    </div>

    <div class="terminate-modal">
      <sweet-modal overlay-theme="dark" ref="terminateModal" :hideCloseButton="true" width="1000px">
        <app-market-terminate-modal :card="card" @close="$refs.terminateModal.close()" @stop="stop"></app-market-terminate-modal>
      </sweet-modal>
    </div>


    <div v-if="vSuccessModal" class="stop-modal-container">
      <sweet-modal overlay-theme="dark" ref="terminateModalSuccess" :hideCloseButton="true">
        <div>
          <div class="modal-header">
            <div>이용해지</div>
            <img src="/static/icon/fi_close_outline.svg" class="svg-sub2 unselect" @click="vSuccessModal=false">
          </div>
          <div style="padding:120px 54px">
            <svg width="54" height="54" viewBox="0 0 24 24" fill="none">
              <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15 9L9 15" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 9L15 15" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="subtitle4 main margin-bottom-16" style="margin-top:20px;">
              <div>추가한 {{ terminateModalData[this.card.app_type].title }}를 <br> 이용 중지 완료되었습니다.</div>
              <div class="flex-center">
                <div style="width:360px;text-align:left">
                  <ul style="list-style:disc">
                    <template v-for="(content, c_idx) in terminateModalData[this.card.app_type].s_contents">
                      <li :key="`s_content-${c_idx}`" class="body4 sub3" style="margin-top:8px">
                        {{ content }}
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>

            <div class="flex-center" style="margin-top:60px">
              <div class="buttons">
                <button class="button is-primary" @click="clickInquiry('terminateModalSuccess')">1:1 문의</button>
                <button class="button is-gray" @click="vSuccessModal=false">닫기</button>
              </div>
            </div>
          </div>
        </div>
      </sweet-modal>
    </div>

    <review-reg-modal ref="reviewModal" :rate="false" :product="card.product" @done="onReviewDone"></review-reg-modal>
  </div>
</template>

<script>
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  import {SweetModal} from 'sweet-modal-vue'
  import AppMarketTerminateModal from "../modal/AppMarketTerminateModal";
  import ReviewRegModal from "../modal/ReviewRegModal";

  export default {
    name: "CardOrderProduct",
    props: {
      card: {
        type: Object
      }
    },
    components: {
      ReviewRegModal,
      AppMarketTerminateModal,
      SweetModal
    },
    mixins: [
      ServiceAdminAPIMixin
    ],
    data() {
      return {
        vSuccessModal: false,
        vModal: false,
        expand: false,
        terminateModalData: {
          package: {
            title: '플랫폼 팩 서비스',
            contents: ['관리자 사이트의 상품>카테고리관리에서 해당 팩의 연결을 해제한 후 이용 중지를 신청해주세요.',
              '해지한 팩과 관련된 상품은 관리자 사이트의 상품목록에는 남아있지만, 프론트 페이지에는 비노출 됩니다.',
              '해지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.'],
            s_contents: ['해지한 팩과 관련된 상품은 관리자 사이트의 상품목록에는 남아있지만, 프론트 페이지에는 비노출 됩니다.',
              '이용 중지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.']
          },
          plugin: {
            title: '플러그인',
            contents: ['플러그인 중도 이용 중지 시, 관련 기능이나 페이지에 오류가 생길 수 있으며 이를 확인하고 동의한 것으로 간주합니다.',
              '이용 중지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.'],
            s_contents: ['이용 중지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.']
          },
          service: {
            title: '서비스',
            contents: ['이용 중지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.'],
            s_contents: ['이용 중지와 관련한 문의 사항이 있을 경우 1:1 문의를 통해 문의해 주시기 바랍니다.']
          }
        }
      }
    },
    methods: {
      clickFeature() {
        const featureLink = [
          {
            path: 'service/setting',
            keys: ['virtual_account', 'naverpay', 'kakaopay', 'mobile_payment', 'account_transfer', 'pg_card',]
          },
          {
            path: 'service/account',
            keys: ['sns_signin']
          },
          {
            path: 'partner',
            keys: ['partner_video']
          },
          {
            path: 'product',
            keys: ['recent_product', 'product_image_upload', 'product_video', 'product_priority', 'sns_inquiry',
              'product_file_upload', 'time_sale']
          },
          {
            path: 'exhibition_partner_product',
            keys: ['partner_product_exhibition']
          },
          {
            path: 'exhibition_partner',
            keys: ['partner_exhibition']
          },
          {
            path: 'order',
            keys: ['order_detail_print']
          },
          {
            path: 'service/account?menu=verify',
            keys: ['nice_identity_verify', 'identity_verify', 'age_verify']
          },
          {
            path: 'filter',
            keys: ['category_filterset']
          },
          {
            path: 'partner/approval/all',
            keys: ['partner_approval']
          },
          {
            path: 'supervisor',
            keys: ['supervisor']
          },
          {
            path: 'service/account?menu=channel_io',
            keys: ['channel']
          },
          {
            path: 'couponpoint/setting',
            keys: ['point_coupon']
          },
          {
            path: 'push',
            keys: ['app_notification']
          },
          {
            path: 'product/evaluation',
            keys: ['product_review_rate']
          },
          {
            path: 'user/param',
            keys: ['user_param']
          },
          {
            path: 'partner/param',
            keys: ['partner_param']
          },
          {
            path: 'product/param',
            keys: ['product_param']
          },
          {
            path: 'product/grade',
            keys: ['partner_grade']
          },
          {
            path: 'guide',
            keys: ['guide']
          },
          {
            path: 'partner/inquiry/new',
            keys: ['partner_inquiry']
          },
          {
            path: 'popup',
            keys: ['popup']
          },
          {
            path: 'product/approval/new',
            keys: ['product_approval']
          },
          {
            path: 'board',
            keys: ['board']
          },
          {
            path: 'user/grade',
            keys: ['user_grade']
          },
          {
            path: 'service/info',
            keys: ['domain']
          }
        ];
        let pack = this.card.service.package;
        let feature = featureLink.find(i => i.keys.indexOf(this.card.product.key) > -1);
        let path = feature.path;
        if(path === 'order') {
          if(pack === 'delivery') {
            path = 'delivery/order_list_pay_all';
          } else {
            path = `${pack}/order_list_all`;
          }
        }

        window.open(`${this.userService.domain}/office/admin/${path}`,'_blank');
      },
      onReviewDone() {
        this.card.has_review = true;
        this.$forceUpdate();
      },
      clickReviewReg() {
        this.$refs.reviewModal.open();
      },
      clickInquiryDetail() {
        window.parent.opener.postMessage({close: true, inquiry:true, inquiryId: this.card.inquiry}, '*');
      },
      clickInquiry(modal_name) {
        if(modal_name !== '') {
          this.$refs[modal_name].close();
        }
        window.open(`${this.userService.domain}/office/admin/launchpack/inquiry/customer/create?id=${this.card.inquiry}`,'_blank');
      },
      onCloseModal() {
        setTimeout(()=>{
          this.vModal = false;
        },500);
      },
      clickStatus() {
        if(this.card.status === 3) {
          this.routerPush('detail?id='+this.card.product.id);
        }
      },
      statusText(status) {
        const arr = {
          1: '신청 중',
          2: '이용 중',
          3: '다시 이용하기',
          4: '안내 완료',
          5: '결제 완료',
          6: '작업 중'
        };

        return arr[status];
      },
      discountText(product) {
        let text = '';
        if (product.is_discount) {
          if (product.discount_rate !== 0 && product.discount_type === 0) {
            text = product.discount_rate + '%'
          } else if (product.discount_price !== 0 && product.discount_type === 1) {
            text = product.discount_price + '원';
          }
        }
        return text;
      },
      clickStop() {
        this.vModal = true;
        this.$nextTick(() => {
          this.$refs.stopModal.open();
        });
      },
      openTerminateModal() {
        this.$refs.stopModal.close();
        this.vModal = false;
        this.$nextTick(()=> {
          this.$refs.terminateModal.open();
        });
      },
      stop() {
        let params = {
          status: 3
        };
        this.request.serviceAdmin.patch(`launchpack/v1/service/${this.userService.id}/app_order/${this.card.id}`, params).then(res=>{
          if(res.status === 200) {
            this.request.serviceAdmin.get(`launchpack/service/${this.userService.id}`).then(res=> {
              this.$store.commit('setUserService', res.data);
              let card = this.card;
              card.status = 3;
              card.end_date = params.end_date;
              this.$emit('card', card);
              this.$refs.terminateModal.close();

              // 이용 중지 완료 모달 띄움
              this.vSuccessModal = true
              this.$nextTick(()=> {
                this.$refs.terminateModalSuccess.open();
              });
            });
          }
        });
      }
    },
    computed: {
      showNextDate() {
        if(!this.card.next_date) {
          return false;
        } else if(this.card.date_unit === 0) {
          return this.dayjs(this.card.next_date).diff(this.dayjs(), 'day') >= 0;
        } else {
          return true;
        }
      },
      img() {
        return this.card.product.img;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .card-container
    padding-top 16px
    border-bottom 1px solid $gray1
  .product-img
    width 80px
    height 80px
    border-radius 12px
    object-fit cover
    object-position center

  .card-status-1
  .card-status-2
  .card-status-3
  .card-status-4
  .card-status-5
  .card-status-6
  .button-review
    border-radius 50px
    padding 0 20px
    line-height 32px
    height 32px
    font-size 14px
    text-align center
    min-width 120px
  .card-status-1
  .card-status-4
  .card-status-5
  .card-status-6
    background-color $gray2
    color $sub2
  .card-status-2
    background-color $main
    color white
    svg
      margin-right 4px
      width 12px
      height 12px

  .button-review
    background-color $primary
    color white

  .card-status-3
    border 1px solid $main
    color $main

  .box-info
    background-color $gray4
    padding 20px
  .info-title
    width 94px
    margin-right 24px
  .info-content
    flex 1

  .button-stop
    width 100px
    height 30px
    position absolute
    right 20px
    top calc(50% - 15px)

  .dim
    position absolute
    background-color rgba(0,0,0,0.56)
    text-align center
    color white
    font-size 15px
    font-weight 500
    top 0
    left 0
    width 100%
    height 100%
    line-height 80px

  .buttons
    display grid
    grid-template-columns repeat(2, 158px)
    grid-column-gap 16px
</style>
<style lang="stylus">
  @import '~assets/css/lp_main'
  .stop-modal-container
    .sweet-modal.is-alert .sweet-content
      padding 0 !important
    .modal-header
      padding 16px 24px
      text-align left
      font-size 16px
      font-weight 500
      color $main
      background-color $gray2
      display flex
      align-items center
      justify-content space-between

  .terminate-modal
    .sweet-modal.is-alert .sweet-content
      padding 32px
</style>
